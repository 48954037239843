import React, { Component } from 'react'
import { connect } from 'react-redux'
import { languageSwitchItem } from '../actions/locales'
import i18next from 'i18next'
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'
import { cipher } from '../actions/stringEncodersFuncs'
import { createSession, sendInputData, sendSessionOption, sendKeyContainer, decryptData,
			getDecryptedData, deleteSession, sendTokenPath, getDecryptorStatus, decryptVtco, premission2fVtco, premissionVtco } from '../actions/api'
import { setInputFileForDecrypt, clearInputFileForDecrypt, setDecryptedData, clearDecryptedData, setDecryptEncodingType,
		    setInputTextForDecryptUintArr, setInputTextForDecrypt, setDecryptText, clearDecryptText, clearInputTextForDecryptUintArr,
		    clearInputTextForDecrypt, setDecryptTextExpand, setErrorData, setShowErrorPopup } from '../actions/localStates'
import { StringToUTF16LEArray, fromByteArray, StringToArray, toByteArray, UTF16LEArrayToString, ArrayToString } from '../actions/stringEncodersFuncs'
import { bindActionCreators } from 'redux';

import TwoFactorConfirmPopup from '../components/body/TwoFactorConfirmPopup'

class DecryptTextContainer extends Component {

	constructor(props) {
    super(props);

	    this.state = {
			isLoading: false,
			uuid: null,
			settedToken: null,
			caId: null,
			downloadIsAvailable: true,
			collapseDecryptTextShow: "",
			showVtcoConfirmPopup: false,
      		numberOfPinInput: 0,
			needToReEnterPin: false
		}

		this.withToken = this.withToken.bind(this)
		this.textEncodingHendler = this.textEncodingHendler.bind(this)
		this.textInputHendler = this.textInputHendler.bind(this)

		this.copyTextToClipboard = this.copyTextToClipboard.bind(this)
	}

	buildFileForDecryptSelector(){
		const fileForDecrypt = document.getElementById('fileForDecryptUpload');
		return fileForDecrypt;
	}

	expandDecryptText() {
		this.props.actions.setDecryptTextExpand(this.props.expandedCardsReducer.decryptTextExpanded)
		this.props.expandedCardsReducer.decryptTextExpanded ? this.setState({collapseDecryptTextShow: "card-body collapse"}) : this.setState({collapseDecryptTextShow: "card-body collapse show"})
	}

	componentDidMount(){
		this.props.expandedCardsReducer.decryptTextExpanded ? this.setState({collapseDecryptTextShow: "card-body collapse show"}) : this.setState({collapseDecryptTextShow: "card-body collapse"})
    	this.fileForDecrypt = this.buildFileForDecryptSelector();
    	if (!this.props.pkLocalState.isActive) {
			this.cleanUpForm()
		}
	}

	textEncodingHendler(e) {
		this.props.actions.setDecryptEncodingType(e.target.value)
	}

	handleFileForDecrypt(e) {
	    e.preventDefault();
	    this.fileForDecrypt.value = null;
	    this.fileForDecrypt.click()
	    document.activeElement.blur()
	}

	textInputHendler(e) {
		this.props.actions.setInputTextForDecrypt(e.target.value)
		this.props.actions.clearDecryptText()
	}

	cleanUpFileForDecrypt(){
		this.props.actions.clearInputFileForDecrypt()
		this.props.actions.clearDecryptedData()
	}

	cleanUpForm(){
		this.props.actions.clearDecryptText()
		this.props.actions.clearInputTextForDecryptUintArr()
		this.props.actions.clearInputTextForDecrypt()
	}

	copyTextToClipboard () {
		var textArea,
        copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
		};

		copy(this.props.defaultState.decryptText);
	}

	withToken(caId, settedToken) {
		var arr, dsArr, _this = this, url, errorMessage;

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		this.props.defaultState.textEncoding === 'UTF-16LE'
					? arr = this.props.actions.StringToUTF16LEArray(this.props.defaultState.textForDecrypt, false)
					: arr = this.props.actions.StringToArray(this.props.defaultState.textForDecrypt, true)
		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForDecryptUintArr(u8Arr)

		if (this.props.defaultState.textForDecrypt.length > 0) {
			try {
	            dsArr = this.props.actions.toByteArray(this.props.defaultState.textForDecrypt)
				var u8DSArr = new Uint8Array(dsArr);
	        } catch (e) {
	        	errorMessage = {
                  message: i18next.t("ds:illegalBase64DataFormat")
                }
                this.props.actions.setErrorData(errorMessage)
                this.props.actions.setShowErrorPopup(true)

	            // alert(i18next.t("ds:illegalBase64DataFormat"));
	            return;
	        }
		}

		this.setState({isLoading: true})
		this.props.actions.createSession(url, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        	.then((response) => {
				this.setState({uuid: response.ticketUuid})
				console.log(response);
			return this.props.actions.sendInputData(url, this.state.uuid, u8DSArr, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
				.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.state.caId, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, this.state.settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
            	.then(() => this.props.actions.decryptData(url, this.state.uuid, keyContainerPassword, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then(() => this.props.actions.getDecryptedData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	           	.then((response) => {
	           		if (response !== null && response.message === undefined) {
	            		var reader = new FileReader();
		                reader.onload = function () {
		                    var arrayBuffer = reader.result;
		                    var uintArray = new Uint8Array(arrayBuffer);
		                    var arr = [].slice.call(uintArray);
		                    var s = _this.props.defaultState.textEncoding === 'UTF-16LE'
		                    	? _this.props.actions.UTF16LEArrayToString(arr)
		                    	: _this.props.actions.ArrayToString(arr)
		                    _this.props.actions.setDecryptText(s)
		                }
		                reader.readAsArrayBuffer(response);
                    	console.log(response)
            		}
                })
                .then(() => this.props.actions.getDecryptorStatus(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
                .then((response) => {
                	if (response.failureCause !== undefined && response.failureCause.length > 0) {
                    	console.log(response)
                    	errorMessage = {
                          message: response.message,
                          techMessage: response.failureCause
                        }
                        this.props.actions.setErrorData(errorMessage)
                        this.props.actions.setShowErrorPopup(true)

                    	// alert(response.failureCause)
            		} else {
            			// alert(i18next.t("dec:decryptedDataDownloadedSuccess": "decryptedDataDownloadedSuccess"))
            		}
            		this.setState({isLoading: false})
                })
	            .then(() => this.props.actions.deleteSession(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
        })
	}

	getBlobData() {
		var _this = this
	    var saveBlob = (function () {
	        var a = document.createElement("a");
	        document.body.appendChild(a);
	        a.style.cssText = "display: none";
	        return function (blob, fileName) {
	        	if (navigator.appVersion.toString().indexOf('.NET') > 0) {
	        		var name = fileName.lastIndexOf(".") < 2 ? fileName : fileName.substr(0, fileName.lastIndexOf("."));
		            window.navigator.msSaveBlob(blob, name);
		            
		       } else {
		           var url = window.URL.createObjectURL(blob);
		            a.href = url;
		            a.download = fileName.lastIndexOf(".") < 2 ? fileName : fileName.substr(0, fileName.lastIndexOf("."));
		            a.click();
		            window.URL.revokeObjectURL(url);

			    }
			    window.setTimeout(function () {
			    	_this.setState({downloadIsAvailable: true})
			    }, 1000)
	        };
	    }());

	    this.setState({downloadIsAvailable: false})
	    saveBlob(this.props.defaultDecrState.decrData, this.props.defaultDecrState.fileForDecryptName);
	}

	selectDecryptVtco () {
		if (this.props.pkLocalState.isTwoFactorEnable) {
			// this.createVtcoSign2f()
			this.setState({numberOfPinInput: this.props.base.numberOfPinInput - 1});
			this.setState({needToReEnterPin: true});
			this.setState({"showVtcoConfirmPopup": true})
		} else {
			this.decryptVtco()
		}
	}

	closeVtcoConfirm (value) {
		if (value === true) {
			this.setState({"showVtcoConfirmPopup": false})
			return;
		} else {
			if (this.state.showVtcoConfirmPopup === true && this.state.needToReEnterPin === true) {
				if (this.state.numberOfPinInput > 0) {
					this.decryptVtco2f()
				} else {
					this.setState({needToReEnterPin: false});
					this.setState({numberOfPinInput: 0});
					this.setState({isLoading: false});
				}
			}

			this.setState({"showVtcoConfirmPopup": false})
		}
	}

	decryptVtco2f () {
		var data, _this = this, errorMessage;
		this.setState({isLoading: true})

		data = {
		    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
		    "operationType": "DECIPHERMENT",
		    "permissionTtlSeconds" : 10,
		    "permissionRepetition" : 2
		}

		this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    .then((response) => {
	    	if (response.code !== undefined) {
	    		_this.setState({isLoading: false})
	    		errorMessage = {
                  message: response.message,
                  techMessage: response.techMessage
                }
                _this.props.actions.setErrorData(errorMessage)
                _this.props.actions.setShowErrorPopup(true)

	    		// var msg = response.message + "\n" + response.techMessage
	    		// alert(msg);
	    	} else {
	    		data = {
				    "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "DECIPHERMENT",
				    "twoFactorCode" : _this.props.pkLocalState.vtcoTwoFactorCode
				}

	    		_this.props.actions.premissionVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    		.then((response) => {
			    	if (response.code !== undefined) {
			    		if (response.code === "AUTHCOMMON-14") {
	                		var currentCount = _this.state.numberOfPinInput

	                		if (currentCount === 0) {
	                			currentCount = _this.props.base.numberOfPinInput - 1;
	                		} else {
	                			currentCount = currentCount - 1;
	                		}

	                		_this.setState({numberOfPinInput: currentCount});
							_this.setState({needToReEnterPin: true});
							_this.setState({showVtcoConfirmPopup: true})
	                	} else {
	                		_this.setState({isLoading: false})
	                		errorMessage = {
		                      message: response.message,
		                      techMessage: response.techMessage
		                    }
		                    _this.props.actions.setErrorData(errorMessage)
		                    _this.props.actions.setShowErrorPopup(true)

				    		// var msg = response.message + "\n" + response.techMessage
				    		// alert(msg);
	                	}
			    	} else {
			    		_this.decryptVtco();
			    	}
			    })
	    	}
	    })
	}

	decryptVtco () {
		var _this = this, errorMessage;
		this.setState({isLoading: true})

		var encodedString = this.props.defaultState.textForDecrypt;

		var jsonObject = {
        	pin : _this.props.pkLocalState.vtcoPin,
			dataToDecipher : [
				encodedString
			]
	    };

	    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

	    var data = {
	        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
	        "encryptedData": cipherResult.encryptedData,
	        "secretKey": cipherResult.secretKey
	    }

	    _this.props.actions.decryptVtco(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
	    .then((response) => {
	    	if (response.code !== undefined) {
	    		_this.setState({isLoading: false})
	    		errorMessage = {
                  message: response.message,
                  techMessage: response.techMessage
                }
                _this.props.actions.setErrorData(errorMessage)
                _this.props.actions.setShowErrorPopup(true)

	    		// var msg = response.message + "\n" + response.techMessage
	    		// alert(msg);
	    	} else {
	    		_this.props.actions.setDecryptText(atob(response[0]))
		    	console.log(response)
		    	_this.setState({isLoading: false})
	    	}
	    })
	}

	decrypt() {
		var arr, dsArr, _this = this, url, errorMessage;
		let settedToken

		if (this.props.base.isVtco) {
			this.selectDecryptVtco();
			return;
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		let caId = {
	        caId: this.props.pkLocalState.selectedKeyContainer
	    }

		this.props.defaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.defaultState.textForDecrypt, false)
			: arr = this.props.actions.StringToArray(this.props.defaultState.textForDecrypt, true)

		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForDecryptUintArr(u8Arr)

		if (this.props.defaultState.textForDecrypt.length > 0) {
			try {
	            dsArr = this.props.actions.toByteArray(this.props.defaultState.textForDecrypt)
				var u8DSArr = new Uint8Array(dsArr);
	        } catch (e) {
	        	errorMessage = {
                  message: i18next.t("ds:illegalBase64DataFormat")
                }
                _this.props.actions.setErrorData(errorMessage)
                _this.props.actions.setShowErrorPopup(true)

	            // alert(i18next.t("ds:illegalBase64DataFormat"));
	            return;
	        }
		}

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
				let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
	            this.setState({"settedToken": keyPath})
	            this.setState({"caId": caId})
	            this.withToken()
			} else {
				this.setState({isLoading: true})
				this.props.actions.createSession(url, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
		        	.then((response) => {
						this.setState({uuid: response.ticketUuid})
						console.log(response);
					return this.props.actions.sendInputData(url, this.state.uuid, u8DSArr, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
						.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, caId, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
		            	.then(() => this.props.actions.decryptData(url, this.state.uuid, this.props.pkLocalState.keyContainerPassword, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then(() => this.props.actions.getDecryptedData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			           	.then((response) => {
			           		if (response !== null && response.message === undefined) {
			            		var reader = new FileReader();
				                reader.onload = function () {
				                    var arrayBuffer = reader.result;
				                    var uintArray = new Uint8Array(arrayBuffer);
				                    var arr = [].slice.call(uintArray);
				                    var s = _this.props.defaultState.textEncoding === 'UTF-16LE'
				                    	? _this.props.actions.UTF16LEArrayToString(arr)
				                    	: _this.props.actions.ArrayToString(arr)
				                    _this.props.actions.setDecryptText(s)
				                }
				                reader.readAsArrayBuffer(response);
		                    	console.log(response)
		            		}
		                })
		                .then(() => this.props.actions.getDecryptorStatus(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
		                .then((response) => {
		                	if (response.failureCause !== undefined && response.failureCause.length > 0) {
		                    	console.log(response)
		                    	errorMessage = {
		                          message: response.message,
		                          techMessage: response.failureCause
		                        }
		                        this.props.actions.setErrorData(errorMessage)
		                        this.props.actions.setShowErrorPopup(true)

		                    	// alert(response.failureCause)
		            		} else {
		            			// alert(i18next.t("dec:decryptedDataDownloadedSuccess": "decryptedDataDownloadedSuccess"))
		            		}
		            		this.setState({isLoading: false})
		                })
			            .then(() => this.props.actions.deleteSession(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
		        })
		    }
		} else {
			this.setState({"settedToken": settedToken})
            this.setState({"caId": caId})
            this.withToken()
		}
    }

	render() {
		const isEnabled = this.props.defaultState.textForDecrypt.length > 0 && !this.state.isLoading
		const isTextCopyEnabled = this.props.defaultState.decryptText && !this.state.isLoading

		return (
            <div className="row" style={{paddingTop: "10px"}}>
				<div className="col-12">
	                <div className="card">
						<div className="card-header" onClick={this.expandDecryptText.bind(this)} role="button">
							{
								this.props.expandedCardsReducer.decryptTextExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
								: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
							}&nbsp;{i18next.t("textData")}
						</div>
	                  	<div className={this.state.collapseDecryptTextShow}>
	                  		<div className="form">
	                    		<label>{i18next.t("encoding")}</label>
	                    		<div className="form-check-inline">
		                            <label className="form-check-label" style={{marginLeft: "7px"}}>
		                                <input className="form-check-input" value="UTF-16LE" type="radio" checked={this.props.defaultState.textEncoding === 'UTF-16LE'} onChange={this.textEncodingHendler}/>
		                                <span className="form-check-label">{i18next.t("utf16LeEncoding")}</span>
		                            </label>
		                            <label className="form-check-label" style={{marginLeft: "7px"}}>
		                                <input className="form-check-input" value="UTF-8" type="radio" checked={this.props.defaultState.textEncoding === 'UTF-8'} onChange={this.textEncodingHendler} />
		                                <span className="form-check-label">{i18next.t("utf8Encoding")}</span>
		                            </label>
			                    </div>
	                    	</div>
	                    	<div>
	                    		<label>{i18next.t("dec:encryptedBase64DataTitle")}</label>
		                        <div className="input-group mb-4">
									<textarea id="dscTextForDs" rows="3" className="form-control" value={this.props.defaultState.textForDecrypt} onInput={this.textInputHendler}></textarea>
								</div>
	                    	</div>
	                    	<div className="row">
								<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
			                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.decrypt.bind(this)} disabled={!isEnabled}>{i18next.t("dec:performBinaryDataDecryption")}
			                        	{
			                              	this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
			                                : null
			                            }
			                        </button>
		                        </div>
		                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-left-padding">
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={this.state.isLoading} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
		                        </div>
		                    </div>
	                        <div style={{"paddingTop": "15px"}}>
								<label>{i18next.t("dec:decryptedTextBase64Title")}</label>
								<textarea id="dscDsBase64" rows="3" className="form-control" value={this.props.defaultState.decryptText} readOnly></textarea>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-right-padding" style={{"paddingTop": "10px", "paddingLeft": "0px"}}>
	                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={!isTextCopyEnabled} onClick={this.copyTextToClipboard.bind(this)}>{i18next.t("copyToClipboardTitle")}</button>
	                        </div>
	                    </div>
	                </div>  
                </div>
                {
					this.state.showVtcoConfirmPopup
					? <TwoFactorConfirmPopup initAction={this.closeVtcoConfirm.bind(this)} title={"decryptConfirm"} />
					: null
				}
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        defaultState: state.decryptTextReducer,
        connectionStatus: state.connectionStatusReucer,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    createSession,
	    sendInputData,
	    sendSessionOption,
	    sendKeyContainer,
	    decryptData,
	    getDecryptedData,
	    getDecryptorStatus,
	    deleteSession,
	    setInputFileForDecrypt,
	    clearInputFileForDecrypt,
	    setDecryptedData,
	    clearDecryptedData,
	    sendTokenPath,
	    setDecryptEncodingType,
	    setInputTextForDecryptUintArr,
	    setInputTextForDecrypt,
	    StringToUTF16LEArray, fromByteArray, StringToArray, toByteArray,
	    setDecryptText,
	    UTF16LEArrayToString,
	    ArrayToString,
	    clearDecryptText,
	    clearInputTextForDecryptUintArr,
	    clearInputTextForDecrypt,
	    setDecryptTextExpand, decryptVtco,
	    cipher, premission2fVtco, premissionVtco,
	    setErrorData, setShowErrorPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DecryptTextContainer);
